import React from 'react';
import './index.module.scss';

const IndexPage = () => {
  return (
    <div styleName='container'>
      <div styleName='brand'>Jayant's</div>
      <div styleName='brand-l2'>experiments</div>
    </div>
  );
};

export default IndexPage;
